<template>
  <div
    class="dp-flex justify-btw"
    style="height: 70px"
  >
    <div class="nameWrap">
      <div class="flex">
        <img
          v-show="msg.ad.smartPerformanceCampaign"
          class="smart-icon"
          src="@/assets//image/smart-icon.png"
          alt=""
        />
        <div
          @click="getChidren"
          v-show="!showInput"
          class="flex-1 w-100 ellipsis-1 link"
        >
          <el-tooltip
            :content="name"
            placement="bottom"
          >
            <span> {{ name || '--' }}</span>
          </el-tooltip>
        </div>
        <span
          type="text"
          v-show="!showInput && edit && !isTTSmartForbidden"
          class="mx-5 el-icon-edit"
          @click="editName"
        ></span>
        <el-input
          v-show="showInput"
          v-model="newName"
          type="textarea"
          style="height: 46px; font-size: 12px"
          resize="none"
          :autosize="{
            minRows: 2,
            maxRows: 2,
          }"
        >
        </el-input>
      </div>
      <div
        v-show="showInput"
        class="dp-flex justify-end"
      >
        <el-button
          type="text"
          @click="uploadName"
          size="mini"
          :loading="loading"
          >确定
        </el-button>
        <el-button
          type="text"
          @click="showInput = false"
          size="mini"
          >取消
        </el-button>
      </div>
      <el-tooltip
        :content="adId"
        placement="bottom"
      >
        <p
          class="comPainId ellipsis-1"
          v-show="!showInput"
        >
          ID：{{ adId }}
        </p>
      </el-tooltip>

      <div
        class="dp-flex"
        v-show="!showInput"
      >
        <p>
          <el-button
            type="text"
            @click="$emit('copy', msg)"
            v-show="canCopy"
            size="mini"
            :disabled="isTTSmartForbidden"
            >复制</el-button
          >
          <el-button
            v-if="isSnapchat"
            type="text"
            @click="$emit('handelEdit', msg)"
            size="mini"
            >编辑</el-button
          >
          <el-button
            type="text"
            @click="$emit('log', msg)"
            v-show="level == 'campaign' || level == 'adset'"
            size="mini"
            >操作日志</el-button
          >
          <el-button
            type="text"
            @click="$emit('todayTrend', msg)"
            v-show="level == 'campaign'"
            size="mini"
            >当日趋势</el-button
          >
          <el-popconfirm
            title="确认删除吗？删除后广告系列将不在推广且无法进行任何操作"
            @confirm="$emit('delete', msg)"
            v-if="status != 'ACTIVE'"
          >
            <el-button
              type="text"
              slot="reference"
              class="ml-10"
              size="mini"
              >删除</el-button
            >
          </el-popconfirm>
        </p>
      </div>
    </div>
    <div
      class="dp-flex flex-d-c align-self-c justify-c ml-5"
      v-show="!showInput"
    >
      <el-switch
        v-model="status"
        active-value="ACTIVE"
        inactive-value="PAUSED"
        :disabled="!(status == 'ACTIVE' || status == 'PAUSED') || isTTSmartForbidden"
        size="mini"
        @change="changeSatatus"
      ></el-switch>
      <div
        class="dp-flex flex-d-c AIRecommendation"
        v-if="msg.ad.aiSuggestion"
      >
        <div class="dp-flex align-item-c">
          <!-- <img src="@/assets/icon/AI.jpg" /> -->
          <div
            :class="[
              'iconfont',
              'icons',
              AiSuggestionTypes[msg.ad.aiSuggestion.type].icon,
              AiSuggestionTypes[msg.ad.aiSuggestion.type].class,
              isOverTime(msg.ad.aiSuggestion.suggestionTime) ? 'overTime' : '',
            ]"
          ></div>
          <span
            :class="{
              [AiSuggestionTypes[msg.ad.aiSuggestion.type].class]: true,
              overTime: isOverTime(msg.ad.aiSuggestion.suggestionTime),
            }"
          >
            {{ msg.ad.aiSuggestion.message }}
          </span>
        </div>
        <div
          class="timeGrey"
          :class="{ overTime: isOverTime(msg.ad.aiSuggestion.suggestionTime) }"
        >
          <i class="el-icon-time"></i> {{ msg.ad.aiSuggestion.suggestionTime.split(' ')[1].slice(0, 5) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const AiSuggestionTypes = {
  PAUSE: {
    icon: 'icon-guanting',
    class: 'cF88',
  },
  SUB_BUDGET: {
    icon: 'icon-zengjiayusuan-copy',
    class: 'cFB6',
  },
  ADD_BUDGET: {
    icon: 'icon-zengjiayusuan',
    class: 'c80D580',
  },
  NO_OP: {
    icon: 'icon-xianzhuang',
    class: 'c99bbff',
  },
};

import { mutateStatus, copyAdAsync, mutateName } from '@/api/multiPlatform';
export default {
  props: {
    msg: {
      type: Object,
      default: () => {},
    },
    level: {
      type: String,
      default: 'campaign',
    },
    index: {
      type: Number,
      default: 0,
    },
    params: {
      type: Object,
      default: () => {},
    },
    edit: Boolean,
  },

  computed: {
    name() {
      return this.level == 'campaign'
        ? this.msg.ad.campaignName
        : this.level == 'adset'
        ? this.msg.ad.adGroupName
        : this.msg.ad.adName;
    },
    status: {
      get() {
        return this.level == 'campaign'
          ? this.msg.ad.adCampaignStatus
          : this.level == 'adset'
          ? this.msg.ad.adGroupStatus
          : this.msg.ad.adStatus;
      },
      set(v) {},
    },
    adId() {
      return this.level == 'campaign'
        ? this.msg.ad.campaignId
        : this.level == 'adset'
        ? this.msg.ad.adGroupId
        : this.msg.ad.adId;
    },
    canCopy() {
      // line平台不支持复制
      //广告系列、广告组 谷歌仅支持发现广告
      // 广告层级 仅支持谷歌广告
      if (this.msg.ad.platform == 'line'){
        return false
      }
      if (this.msg.ad.platform == 'snapchat'){
        return true
      }
      if (this.level === 'campaign' || this.level == 'adset') {
        if (this.msg.ad.platform == 'google') return this.msg.ad.objectiveEnum == 'DEMAND_GEN';
        return true;
      } else {
        return this.msg.ad.platform == 'google';
      }
    },
    isTTSmartForbidden() {
      return this.level !== 'campaign' && this.msg.ad.smartPerformanceCampaign;
    },
    isSnapchat() {
      return this.level !== 'campaign' && this.msg.ad.platform === 'snapchat';
    },
  },

  data() {
    return {
      loading: false,
      AiSuggestionTypes,
      copyNum: 1,
      visible: false,
      newName: '',
      showInput: false,
      campaignDailyBudget: this.msg?.ad?.campaignDailyBudget ?? '', //日预算
      adsetDailyBudget: this.msg?.ad?.adsetDailyBudget ?? '', // 组日预算
      aiSuggestion: {
        id: 922108,
        dataTime: '2023-07-20 15:40:31',
        message: '减预算',
        objId: '23857575733840591',
        objType: 'campaign',
        suggestionTime: '2023-07-31 14:58:04',
        type: 'PAUSE',
      },
    };
  },
  methods: {
    //检查AI助手建议时间是不是超过了半小时
    isOverTime(givenDateTime) {
      let now = new Date();
      let diffInMinutes = (new Date(givenDateTime) - now) / (1000 * 60);
      let isOver30Minutes = Math.abs(diffInMinutes) > 30;
      return isOver30Minutes;
    },
    getChidren() {
      if (this.level == 'ad') return;
      this.$emit('getChildren', this.msg);
    },
    //修改状态
    changeSatatus(status) {
      let params = {
        platform: this.msg.ad.platform,
        campaignId: this.msg.ad.campaignId,
        adsetId: this.msg.ad.adGroupId,
        adId: this.msg.ad.adId,
        accountId: this.msg.ad.adAccountId,
        status,
        level: this.level,
        objectiveEnum: this.msg.ad.objectiveEnum,
        clientType: 'pc',
        functionType: 'manual',
      };
      let keys = {
        campaign: 'adCampaignStatus',
        adset: 'adGroupStatus',
        ad: 'adStatus',
      };
      const oldVal = this.status;
      this.$emit('setMsg', this.index, keys[this.level], status);
      mutateStatus({ ...params }).then(
        (res) => {
          if (res.code == 0) {
            this.$message({ type: 'success', message: '修改成功' });
          }
        },
        (err) => {
          this.$emit('setMsg', this.index, keys[this.level], oldVal);
        },
      );
    },

    // 编辑标题
    editName() {
      this.newName = this.name;
      this.showInput = true;
    },
    // 更新名称
    uploadName() {
      console.log('-uploadName ', this.msg);
      const {
        ad: { platform, campaignId, adAccountId, adGroupId, adId, objectiveEnum, smartPerformanceCampaign },
      } = this.msg;
      const newName = this.newName;
      console.log(smartPerformanceCampaign, 'smartPerformanceCampaign');

      let params = {
        platform: platform,
        campaignId: campaignId,
        accountId: adAccountId,
        name: this.newName,
        level: this.level,
        adsetId: adGroupId,
        adId: adId,
        objectiveEnum: objectiveEnum,
        clientType: 'pc',
        functionType: 'manual',
        smartPerformanceCampaign: smartPerformanceCampaign,
      };
      console.log('-uploadName params', params);
      this.loading = true;
      mutateName(params)
        .then((res) => {
          if (res.code == 0) {
            this.showInput = false;
            let keys = {
              campaign: 'campaignName',
              adset: 'adGroupName',
              ad: 'adName',
            };
            this.$set(this.msg.ad, keys[this.level], newName);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('../../../assets/iconfontPlatform/iconfont.css');
.link {
  cursor: pointer;
  &:hover {
    color: #409eff;
    text-decoration: underline;
  }
}
.nameWrap {
  position: relative;
  // padding-right: 40px;
  // max-width: 228px;
  flex: 1;
  width: 0;
  .flex {
    display: flex;
  }

  ::v-deep .el-input--suffix .el-input__inner {
    padding-right: 80px;
  }
  ::v-deep .el-input__suffix {
    display: flex;
    align-items: center;
  }
  .name_buttons {
    // padding-top: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    .el-button + .el-button,
    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
      margin-left: 0;
    }
  }
}
.el-switch {
  justify-content: flex-end;
}
.comPainId {
  color: #999999;
}
.AIRecommendation {
  margin-top: 10px;
  font-size: 12px;
  .icons {
    font-size: 12px;
    margin-right: 5px;
  }
  .c80D580 {
    color: #80d580;
  }
  .c99bbff {
    color: #99bbff;
  }
  .cFB6 {
    color: #ffbb66;
  }
  .cF88 {
    color: #ff8888;
  }

  .timeGrey {
    color: #aaaaaa;
  }
  .overTime {
    color: #bbbbbb;
  }
  img {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
  span {
    color: #ccddff;
    margin-top: 2px;
  }
}
.smart-icon {
  width: 13px;
  height: 15px;
  position: relative;
  top: 4px;
  margin-right: 8px;
}
</style>
